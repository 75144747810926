

































import {
  Component,
  Inject,
  Prop,
  Ref,
  Vue,
  Watch,
} from "vue-property-decorator";
import { IReactionDisposer, reaction } from "mobx";
import { Observer } from "mobx-vue";

@Observer
@Component
export default class AppAvatar extends Vue {
  @Prop() avatar: any;
  @Prop() race!: string;
  @Prop() video!: any;
  @Prop({ default: false }) hideVideo!: boolean;
  @Prop({ default: 444 }) videoSize!: number;
  @Ref("videoPlayer") videoPlayer!: any;

  url: string | null = null;
  type = "video/mp4";
  videoError = false;

  @Watch("avatar", { immediate: true }) async onAvatarChanged() {
    await this.updateImage();
  }
  @Watch("video", { immediate: true }) async onVideoChanged() {
    this.videoError = false;
  }

  async updateImage() {
    try {
      this.url = this.avatar;
    } catch (error) {
      console.error("onAvatarChanged", error);
    }
  }

  onLoadImgError() {
    this.url = require(`../../assets/${this.race.toLowerCase()}-default.png`);
  }
}
